.Sidebar_menu.Scroll {
  height: calc(100vh - 50px) !important;
  ::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Optional: background for the track */
  }

  ::-webkit-scrollbar-thumb {
    background: #0aceec; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Optional: rounded corners for the thumb */
  }
}
.Sidebar_menu {
  border-radius: 20px;
  background: #1e1f25;
  // width: 249px;
  width: 100%;
  height: 93vh;
  position: relative;
  text-align: left;
  // padding-bottom: 50px;
  
  .logo-border {
    //border-bottom: 1px solid #282C38;
    padding: 25px 20px;
    border-bottom: 1px solid #282c38;
    // margin-bottom: 28px; todo
  }

  .collapseSideBar {
    text-align: center;
  }

  .BottomMenu {
    position: relative;
    width: 100%;
    // bottom: -86px; todo  
    ul {
      width: 100%;
    }
  }

  p {
    font-family: Lato, Sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin: 0;
    color: #b2b9cd;
    // padding: 0px 0px 18px 30px;
    padding: 0px 0px 8px 30px; // todo
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &.top-list {
      // todo
      height: calc(100% - 200px) !important;
      overflow-y: auto;
    }
    

    .active_menu {
      background: #2d2f36;
      color: #fff;
      .image-first {
        display: none;
      }
      .image-hover {
        display: inline-block !important;
      }
    }
    li {
      color: #b2b9cd;
      // margin: 0px 5px 10px;
      margin: 0px 5px 5px; // todo
      padding: 13px 15px;
      padding: 8px 15px; // todo
      border-radius: 15px;
      cursor: pointer;

      &:hover {
        background: #2d2f36;
        color: #fff;
        .image-first {
          display: none;
        }
        .image-hover {
          display: inline-block !important;
        }
      }
      span {
        cursor: pointer;
        &:last-child {
          padding-left: 15px;
        }
      }
    }
    .collapsedLi {
      padding-right: 15px;
    }
  }
}
