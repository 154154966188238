.pagination {
  margin: 0;
  padding-top: 19px;

  .prev-li {
    a {
      border-radius: 15px 0px 0px 15px !important;
      &:focus {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  .next-li {
    a {
      border-radius: 0px 15px 15px 0px !important;
      &:focus {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  li {
    a {
      background-color: #141518;
      border-color: #282c38;
      font-family: Lato, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #b2b9cd;
      // height: 40px;
      // width: 40px;
      text-align: center;
      line-height: 26px;
      // &:hover {
      //   background-color: transparent;
      //   border-color: #282c38;
      // }
      // &:focus {
      //   background-color: transparent;
      //   box-shadow: none;
      // }
    }
    .page-link:hover{
      background-color: #141518;
      border: 1px solid #272b35;
    }
    .page-link:focus{
      background-color: #141518;
      border: 1px solid #272b35;
    }

    span {
      background-color: #141518;
      border-color: #282c38;
      font-family: Lato, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #b2b9cd;
      // height: 40px;
      // width: 40px;
      text-align: center;
      line-height: 26px;
    }
  }

  .active {
    span {
      background: #0aceec !important;
      color: #1e1f25;
    }
  }
}
