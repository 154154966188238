.Organization-section {
  .name-textunder {
    color: #0aceec !important;
    cursor: pointer;
    text-decoration: underline;
  }
  .align-right {
    text-align: right;
    .w-export {
      width: 102px;
    }
  }

  .Organization-header {
    display: flex;
    gap: 23px;
    input {
      width: 371px !important;
    }
  }
  .RegisteredButton {
    color: #30e0a1;
    border-radius: 5px;
    background: #30e0a133;
    border: 0;
    padding: 8px;
    font-family: Lato, Sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .Organization-button {
    border-radius: 10px;
    background: #0aceec;
    font-family: Lato, Sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000000;
    padding: 11px 10px;
    &:hover {
      background: #0aceec;
    }
  }

  .organise_tabs {
    border-bottom: 1px dashed #282c38;
    padding: 31px 0px 0px 0px;

    li {
      button {
        text-align: center;
        font-family: Lato, Sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: #9c9fa7;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
        border-bottom: 2px solid transparent;
        padding: 0;
        margin-right: 32px;

        &:hover {
          color: #0aceec;
          border-top: 0;
          border-right: 0;
          border-left: 0;
          border-bottom: 2px solid #0aceec;
        }
      }

      .active {
        color: #0aceec;
        border-bottom: 2px solid #0aceec;
        background: transparent;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
      }
    }
  }
}
