.patientlist_modal {
    .table> :not(caption)>*>* {
        background: #000000;
    }

    .table-striped>tbody>tr:nth-of-type(odd)>* {
        background: #282c38;
    }

    .first-th {
        width: 15%;
    }

    .sec-th {
        width: 73%;
    }

    .status_dropdown {
        button {
            background-color: #000;
        }
    }

    .search-input {
        background-color: #000;
    }

    .modal-dialog {
        width: 1100px;
        margin: 0 auto;
        max-width: 100%;
        height: 100%;
    }

    .modal-content {
        background: #1c1d20;
        width: 1100px;
        margin: 0 auto;
        border-radius: 20px;
        height: 96%;
    }

    .modal-header {
        border: 0;

        .btn-close {
            color: #fff;
            background-image: url("../../../assests/images/dashborad/close.png");
            background-size: 100%;
            opacity: 1;
            box-shadow: none;
        }
    }

    .modal-body {
        padding: 35px 70px 70px 70px;
    }

}

.export-button {
    border-radius: 10px;
    border: 1px solid #0aceec;
    height: 42px;
    width: 100%;
    color: #0aceec;

    &:hover {
        background-color: #0aceec;
        color: #1e1f25;
    }
}

.make_display_none {
    display: none;
}

.dark-dropdown-menu {
    background-color: #343a40 !important;
    /* Bootstrap's dark background color */
    color: white !important;
}

/* If you want to change the color of dropdown items on hover as well */
.dark-dropdown-menu .dropdown-item:hover,
.dark-dropdown-menu .dropdown-item:focus {
    background-color: #495057;
    /* Slightly lighter for contrast */
    color: white;
}

/* Change the color of the dropdown item text */
.dark-dropdown-menu .dropdown-item {
    color: white !important;
}

//   .square-checkbox .form-check-input {
//     border-radius: 0;
//   }

/* Spacing between checkbox and label */
.checkbox-label {
    margin-left: 0.5rem;
    /* Adjust this value as needed */
}

.square-checkbox .form-check-input {
    width: 20px !important;
    height: 20px !important;
    background-color: #282c38 !important;
    border-color: #3c4254 !important;

    &:checked {
        background-color: #0aceec !important;
        border-color: #0aceec !important;
        cursor: pointer !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.PDFIcon-img {
    cursor: pointer;
}

.relative-position {
    position: relative;
}

.absolute-position {
    position: absolute;
    right: 0;
}