.compare-container {
    // background-color: white;
}

.comparison-table {
    border-collapse: collapse;
    width: 100%;
}

.comparison-table-body {
    background-color: #f8f8f8;
}

.comparison-body-part {
    border-top: 1px solid #494949;
    border-bottom: 1px solid #494949;
    border-left: 1px solid #494949;
    padding-left: 30px;
    padding-bottom: 38px;
    color: white;
}

.comparison-posture-score {
    border-top: 1px solid #494949;
    padding: 8px;
    color: white;
}

.comparison-mobility {
    border-top: 1px solid #494949;
    padding: 8px;
    border-right: 1px solid #494949;
    color: white;
}

.comparison-left-right,
.comparison-right-last {
    border-bottom: 1px solid #494949;
    padding: 8px;
    color: #B2B9CD
}

.comparison-right-last {
    border-right: 1px solid #494949;
}

.comparison-category {
    border-left: 1px solid #494949;
    border-right: 1px solid #494949;
    padding: 8px;
    font-weight: bold;
    padding-left: 30px;
}

.category-mobility {
    border-right: 1px solid #494949;
    padding: 8px;
    font-weight: bold;
    color: white;
}

.category-style,
.category-bodyPart {
    font-weight: bold;
    padding: 10px;
    color: white;
}

.category-bodyPart {
    border-left: 1px solid #494949;
    padding-left: 30px;
}

.category-style {
    border-bottom: 1px solid #494949;
}

.last-item {
    border-bottom: none;
}