.dme_outer {
  padding-top: 0px;
}

h1 {
  font-family: Lato, Sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  margin: 0;
}

.dropdownarea {
  position: relative;

  .innerbox {
    position: fixed;
    right: 7%;
    top: 246px;
    z-index: 999;

    button {
      background: transparent;
      width: auto;
      padding: 0px;
    }

    button:focus {
      background: transparent;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }

  .dropdown-menu {
    background: #282c38;
    width: max-content;

    .input-group span:last-child {
      font-size: 14px;
      font-weight: 500;
      color: #b2b9cd;
      margin-left: 10px !important;
      line-height: 26px;
    }

    .form-check-input {
      height: 24px;
      width: 24px;
      border: none;
      margin: 0px;
      background-color: transparent;
    }
    .input-group span:first-child {
      background-color: transparent;
      border-radius: 4px !important;
      margin: 0px;
      padding: 0px;
    }

    .form-check-input:checked {
      background-color: #0aceec;
      box-shadow: none;
      border-color: #0aceec;
      height: 24px;
      margin: 0;
      border-radius: 4px !important;
    }
    .dropdown-item:focus,
    .dropdown-item:hover {
      background-color: #1e1f25;
    }
  }
}

.support {
  color: #0aceec !important;
}

.bottomSpace {
  margin-bottom: 30px;
}

.customloader {
  margin: 20px auto 0px !important;
  display: block !important;
}

.formBox {
  padding: 40px 50px 40px 30px;
  background: #1e1f25;
  border-radius: 20px;
  margin-top: 25px;
  min-height: calc(100vh - 152px);

  p button {
    margin: 0px;
    width: 140px;
  }

  p {
    margin-bottom: 25px;
    color: white;
  }

  h5 {
    font-family: Lato, Sans-serif;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 15px;
  }

  input {
    width: 100%;
  }

  .addIcon {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .closeicon {
    position: absolute;
    top: 15px;
    right: 12px;
  }

  .searchItem {
    width: 95%;
    padding-left: 8px;
    margin: 20px 20px 0px;
    position: absolute;
    transform: translate3d(0px, 38px, 0px);
    top: 0px;
    left: 0px;
    background-color: #1e1f25;
    max-height: 250px;
    overflow-y: auto !important;
    z-index: 1000;

    p {
      font-size: 16px;
      color: #e4e6ec;
      padding: 10px 0px;
      margin: 0px;
      line-height: 30px;

      span {
        display: block;
      }
    }
  }

  button {
    border-radius: 10px;
    background: #0aceec;
    font-family: Lato, Sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000000;
    width: 174px;
    padding: 11px 10px;
    border: none;
    display: block;
    margin: 0px auto 0px;
    height: 45px;
  }

  td.price {
    color: #30e0a1;
  }
}

.errorMessage {
  color: red !important;
  text-align: center;
  font-size: 14px;
  margin-bottom: 1rem;
}

.curserPointer {
  cursor: pointer;
}

.tableArea {
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 331px);

  td,
  th {
    text-wrap: nowrap;
  }

  .athena {
    max-width: 750px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
