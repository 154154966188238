.Patients_section {
  padding-top: 24px;

  h1 {
    font-family: Lato, Sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #fff;
    margin: 0;
  }

  .name-textunder {
    color: #0aceec !important;
  }

  .search-input {
    border-radius: 10px;
    border: 1px solid #3c4254;
    background: transparent;
    padding: 12px;
    height: 42px;
    text-indent: 27px;
    background-color: #1e1f25;
    font-family: Lato, Sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;

    &::placeholder {
      color: #b2b9cd;
    }
  }

  .export-button {
    border-radius: 10px;
    border: 1px solid #0aceec;
    height: 42px;
    width: 100%;
    color: #0aceec;

    &:hover {
      background-color: #0aceec;
      color: #1e1f25;
    }
  }

  .search-img {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .table-margin {
    padding-top: 38px;
  }

  .enable-status {
    width: 209px;
  }

  .posture-status {
    width: 234px;
  }

  .status_dropdown {
    button {
      border-radius: 10px;
      padding: 8px 12px;
      background: #1e1f25;
      border-color: #3c4254;
      font-family: Inter, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 19px;
      height: 42px;

      &:focus {
        box-shadow: none;
        background-color: #1e1f25;
        border-color: #3c4254;
      }

      img {
        float: right;
        position: absolute;
        right: 10px;
      }
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    span {
      color: #b2b9cd;
    }
  }
}

.patient-info {
  .modal-dialog {
    max-width: 100%;
    width: 480px;
    position: absolute;
    top: 47%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .bottom-info {
    padding-top: 24px;

    img {
      margin-right: 10px;
    }

    .col-md-4,
    .col-md-8,
    .col-md-6 {
      color: #fff;
      font-family: Lato, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.07px;
      margin-bottom: 16px;
    }

    p {
      margin: 0;
      text-align: right;
    }
  }

  .modal-header {
    border: 0;

    .btn-close {
      color: #fff;
      background-image: url("../../../assests/images/dashborad/close.png");
      background-size: 100%;
      opacity: 1;
      box-shadow: none;
    }
  }

  .modal-content {
    border-radius: 12px;
    background: #1e1f25;

    .info_section {
      text-align: center;

      .info-bg {
        border-radius: 100px;
        background: #fff;
        color: #1e1f25;
        font-family: Inter, Sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.12px;
        width: 120px;
        height: 120px;
        text-align: center;
        padding-top: 45px;
        margin: 0 auto;
      }

      p {
        text-align: center;
        font-family: Inter, Sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.09px;
        color: #fff;
        padding-top: 12px;
        margin-bottom: 5px;

        &:last-child {
          color: #fff;
          text-align: center;
          font-family: Inter, Sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0.07px;
          padding: 0 0 24px 0px;
          border-bottom: 1px solid #282c38;
        }
      }
    }
  }

  .modal-footer {
    border: 0;

    .delete-button {
      color: #f93440;
      font-family: Inter, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.07px;
      text-transform: capitalize;
      background: transparent;
      border-color: #f93440;
      height: 40px;

      img {
        margin-right: 4px;
        vertical-align: bottom;
      }
    }

    .edit-button {
      font-family: Inter, Sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.07px;
      color: #000;
      background: #0aceec;
      border-color: #0aceec;
      text-transform: capitalize;
      height: 40px;

      img {
        margin-right: 4px;
        vertical-align: bottom;
      }
    }
  }
}

.confirmDelete {
  .modal-dialog {
    max-width: 100%;
    width: 500px;
    position: absolute;
    top: 47%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .modal-body {
    border: 0;
    color: white;
  }

  .modal-header {
    border: 0;
    color: white;

    .btn-close {
      color: #fff;
      background-image: url("../../../assests/images/dashborad/close.png");
      background-size: 100%;
      opacity: 1;
      box-shadow: none;
    }
  }

  .modal-content {
    border-radius: 12px;
    background: #272830;
  }
}

.right-header {
  display: flex;
  gap: 20px;
  float: right;

  .w-export {
    width: 102px;
  }

  .search-input {
    width: 427px !important;
  }
}


.delete-yes-button {
  background: #0aceec !important;
  color: black !important;
}