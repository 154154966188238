.Login_page {
  background-color: #000;
  background-image: url(../../assests/images/login/login-banner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  padding-bottom: 30px;
  .form_align {
    max-width: 100%;
    width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  img {
    width: 327px;
    height: 340px;
    margin: 0 auto;
  }
  .is-invalid-label {
    border-color: red;
    text-align: left;
  }

  h1 {
    text-align: center;
    font-family: Pirulen, Sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    color: #fff;
  }

  p {
    text-align: left;
    font-family: Lato, Sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #b2b9cd;
  }

  .form-label {
    font-family: Lato, Sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #b2b9cd;
  }
  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
    font-family: Inter, Sans-serif;
  }

  input {
    border: 1px solid #b2b9cd;
    background: #000;
    box-shadow: 0px 1px 2px 0px rgb(16 24 40 / 5%);
    color: #ffffff;
    font-family: Lato, Sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    &::placeholder {
      color: #ffffff;
    };

    &:focus {
      background : #000;
      color: #ffffff;
    }
  }
  button {
    border-radius: 10px;
    background: #0aceec;
    font-family: Lato, Sans-serif;
    font-size: 14px;
    color: #000;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 360px;
    height: 42px;
    padding: 11px 10px;
    margin: 10px auto 0px;
    text-align: center;
    &:hover {
      background: #0aceec;
    }
  }
  .verification_code {
    margin: 42px 0px 24px 0px;
    gap: 12px;
    input {
      border-radius: 8px;
      border: 2px solid #0aceec;
      background: #000;
      height: 80px;
      width: 80px;
      align-items: center;
      color: #0aceec;
      text-align: center;
      font-family: Pirulen, Sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 75px;
      letter-spacing: -0.64px;
      padding: 3px;
    }
  }
  .verification_page {
    max-width: 565px;
    margin: 0 auto;
  }
}
.termspopup {
  .h4 {
    padding-left: 20px;
    padding-top: 20px;
  }
  p {
    padding-left: 20px;
    font-size: 15px !important;
    color: #fff !important;
    line-height: 22px !important;
  }
  .modal-dialog {
    min-width: 677px !important;
  }
  .modal-footer {
    padding: 0px 15%;
  }
}
.remeberme {
  margin-left: 12px;
}

.checkb {
  width: 18px;
  height: 18px;
  vertical-align: text-top;
  border-radius: 6px;
}

.name-text {
  color: #0aceec;
  text-decoration: underline;
  cursor: pointer;
}
